/**
 * Improve scroll performance on touch devices
 * set event listeners passive
 */
if ("ontouchstart" in document.documentElement) {
    document.addEventListener("touchstart", ontouchstart, { passive: true });
}

document.addEventListener("DOMContentLoaded", () => {
    /**
     * Resolve Formie ARIA issues
     */
    // aria-hidden with no value
    document.querySelectorAll(`[aria-hidden=""]`).forEach(el => {
        el.setAttribute("aria-hidden", "true");
    });
    // Country Select missing aria-label
    document.querySelectorAll(".iti__selected-flag").forEach(el => {
        el.setAttribute("aria-label", "Select country");
    });
    // Remove deprecated and failing aria attributes
    document.querySelectorAll('[aria-owns]').forEach(el => {
        el.removeAttribute("aria-owns");
    });
    document.querySelectorAll('[aria-activedescendant]').forEach(el => {
        el.removeAttribute("aria-activedescendant");
    });

    /**
     * Resolve Splide ARIA role="group" issue. Just remove it.
     */
    document.querySelectorAll(`.splide__slide[role="group"]`).forEach(el => {
        el.removeAttribute("role");
    });
    document.querySelectorAll(`.splide__slide[role="tabpanel"]`).forEach(el => {
        el.removeAttribute("role");
    });
});
