/**
 * Formie Extra
 * Injected overrides and other tweaks
 */
const formieExtra = () => {
    const htmlBtn = `
        <button type="submit" data-submit-action="submit" class="button btn-basic btn-cta-secondary --blinds --blinds-outline">
            <div class="button__content">
                <span class="button__text">Submit</span>
            </div>
        </button>
    `;

    // replace formie submit with branded button
    document.querySelectorAll("[data-inject-button]").forEach(el => {
        const text = el.querySelector(".fui-submit").innerHTML;
        el.innerHTML = htmlBtn;
        el.querySelector(".button__text").innerHTML = text;
    });
}

document.addEventListener("DOMContentLoaded", formieExtra);

export default formieExtra;
